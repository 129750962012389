import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p><i>From a small Armenian school of about 600 students to a college with thousands, the transition was not easy. Making the change was necessary, 
however, especially in the field of Information Technology. My name is Hagop Arabian, but I am referred to by my middle name Christian, or Chris.
I have many hobbies such as sports, mainly basketball and volleyball, fitness, and video games. When I love doing something I work my hardest to gain the experience
and expertise in that craft, Basketball, for instance, was the one part of my life I will never take for granted because after my days in high school, I wanted to 
play in Lebanon, which was a dream of mine. I worked hard to the point where I was confident enough in my abilities to achieve that, but could not find a way to make it there.
I use those experiences and the discipline of that time, to motivate me to work harder everyday. 
My main goals in life now include living a happy life doing what I love, which is making positive changes in the world using technology. </i></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      