import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Hagop C. Arabian`}</h1>
    <p>{`I am third year college student at California State Univeristy, Los Angeles studying toward a Bachleors degree in Computer Science.
After seeing the advancements of technology and the future it has in the world, I started to look into the field and saw the opportunites hoping to one
day be the reason to make a positive change in the world. `}</p>
    <p><b>My main interests include:</b> Artificial Intelligence, Web Development and Design, and the Software Engineering lifecycle.</p>
 

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      