import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <body style={{
      background: 'transparent',
      margin: '5px'
    }}> 
    <div style={{
        display: 'block',
        padding: '2px',
        margin: 'auto',
        float: 'left',
        width: '400px'
      }}>
    <a href="https://github.com/HCArabian/"> 
        <img src="https://image.flaticon.com/icons/png/512/179/179323.png" width="75px"></img><span> </span>
    </a> 
    <a href="https://www.linkedin.com/in/hagop-christian-arabian-1a018b192/">
        <img style={{
            float: 'right',
            margin: '12px auto'
          }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/LinkedIn_Logo.svg/1280px-LinkedIn_Logo.svg.png" width="200px"></img>
     </a>
    </div>
    </body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      